import { Link, graphql } from "gatsby"

// import { AddThis } from "../components/addthis"
import Breadcrumb from "../components/breadcrumb"
import { Disqus } from "gatsby-plugin-disqus"
import Layout from "../components/layout"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React from "react"
import Seo from "../components/seo"
import TagsList from "../components/tagslist"
import { disqusConfig } from "../utils/disqus"

class BlogPostTemplate extends React.Component {

  render() {
    const { location, path, pageContext, data: { file, mdx, site: { siteMetadata } } } = this.props
    const fullUrl = siteMetadata.siteUrl + location.pathname
    const { previous, next, related } = pageContext
    const description = mdx.frontmatter.description || mdx.excerpt
    const image = mdx.frontmatter.image ? mdx.frontmatter.image.childImageSharp.gatsbyImageData : (file ? file.childImageSharp.gatsbyImageData : null)
    const headerImage = mdx.frontmatter.image ? mdx.frontmatter.image.publicURL : file.publicURL

    return (
      <Layout location={location} title={siteMetadata.title} subtitle={mdx.frontmatter.title} image={image} meta={siteMetadata}>
        <Seo
          title={mdx.frontmatter.title}
          description={description}
          imageURL={headerImage}
        />
        <Breadcrumb path={path} disableLast={true} />
        <article>
          <h1 className="">
            {mdx.frontmatter.title}
          </h1>
          <p className="info">
            <span className="date">{mdx.frontmatter.date}</span>
            <span className="author">{mdx.frontmatter.author}</span>
          </p>
          <MDXRenderer>
            {mdx.body}
          </MDXRenderer>
          <p className="h4 my-4 text-center text-danger kofi">
            If you enjoyed this article won't you please: <a href='https://ko-fi.com/agilitynerd' target='_blank' rel="noopener noreferrer" className="mx-2 border-0"><img height='36px' width='141px' src='/images/kofi-yellow.png' border='0' alt='Buy Me a Coffee at ko-fi.com' /></a> Thanks!
          </p>
          <h2 className="mt-4 h3">Related Tags</h2>
          <div className="tags"><TagsList tags={mdx.frontmatter.tags} /></div>
          <h2 className="mt-4 h3">Related Articles:</h2>
          <ul>
            {related.map(r => <li key={r.slug}><Link to={r.slug}>{r.title}</Link></li>)}
          </ul>
          {/* <AddThis slug={fullUrl} title={mdx.frontmatter.title} description={description} /> */}
          <Disqus config={disqusConfig(fullUrl, mdx.frontmatter.title)} />
        </article>
        <hr className="mb-5" />
        <ul className="article-nav d-print-none"
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <small>
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              </small>
            )}
          </li>
          <li>
            {next && (
              <small>
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </Link>
              </small>
            )}
          </li>
        </ul>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`query BlogPostBySlug($slug: String, $image: String) {
  site {
    siteMetadata {
      title
      siteUrl
      social {
        facebook
      }
    }
  }
  file(relativePath: {eq: $image}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
    publicURL
  }
  mdx(fields: {slug: {eq: $slug}}) {
    id
    excerpt
    body
    frontmatter {
      title
      date(formatString: "DD MMM YYYY")
      description
      tags
      author
      image {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
        publicURL
      }
    }
  }
}
`
